var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"addMenu"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"getSubmitForm":_vm.getSubmitForm},on:{"update":_vm.update},scopedSlots:_vm._u([{key:"saveAfterSlot",fn:function(){return [_c('v-button',{attrs:{"text":"取消"},on:{"click":function($event){return _vm.$router.go(-1)}}})]},proxy:true}])},'form-panel',_vm.submitConfig,false),[[_c('el-form-item',{attrs:{"label":"模板归属","rules":[
          { required: true, message: '请选择模板归属', trigger: 'blur' },
        ],"prop":"domain"}},[_c('v-select',{attrs:{"disabled":_vm.id !== undefined,"options":_vm.moduleListMapOps,"placeholder":"请选择"},model:{value:(_vm.form.domain),callback:function ($$v) {_vm.$set(_vm.form, "domain", $$v)},expression:"form.domain"}})],1),_c('el-form-item',{attrs:{"label":"菜单类型","rules":[
          { required: true, message: '请选择菜单类型', trigger: 'blur' },
        ],"prop":"category"}},[_c('v-select',{attrs:{"disabled":_vm.id !== undefined,"options":_vm.menuCategoryOps,"placeholder":"请选择"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})],1),_c('el-form-item',{attrs:{"label":"父级菜单","rules":[
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],"prop":"parentName"}},[_c('v-input',{attrs:{"isReadonly":"","placeholder":"请输入"},model:{value:(_vm.form.parentName),callback:function ($$v) {_vm.$set(_vm.form, "parentName", $$v)},expression:"form.parentName"}})],1),_c('el-form-item',{attrs:{"label":"菜单名称","rules":[
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],"prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"路由地址","rules":[
          { required: true, message: '请输入路由地址', trigger: 'blur' },
        ],"prop":"path"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.path),callback:function ($$v) {_vm.$set(_vm.form, "path", $$v)},expression:"form.path"}})],1),(_vm.form.domain != 1)?_c('el-form-item',{attrs:{"label":"跳转地址","rules":[
          { required: false, message: '请输入跳转地址', trigger: 'blur' },
        ],"prop":"url"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1):_vm._e(),(
          _vm.isEdit &&
          _vm.form.parentName == '顶级' &&
          (_vm.form.domain == 0 || _vm.form.domain == 2 || _vm.form.domain == 4)
        )?_c('el-form-item',{attrs:{"label":"菜单icon","rules":[
          { required: true, message: '请上传菜单icon', trigger: 'blur' },
        ],"prop":"source"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.source},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "source", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "source", $event)}}})],1):_vm._e(),(
          _vm.isEdit &&
          _vm.form.parentName == '顶级' &&
          (_vm.form.domain == 0 || _vm.form.domain == 2 || _vm.form.domain == 4)
        )?_c('el-form-item',{attrs:{"label":"选中菜单icon","rules":[
          { required: true, message: '请上传菜单icon', trigger: 'blur' },
        ],"prop":"selectSource"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.selectSource},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "selectSource", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "selectSource", $event)}}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: false, message: '请输入排序', trigger: 'blur' }],"prop":"sort"}},[_c('v-input',{attrs:{"type":"number"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1)],(_vm.form.domain == 1)?[_c('el-form-item',{attrs:{"label":"是否打开新页面","rules":[
          { required: true, message: '请选择模板归属', trigger: 'blur' },
        ],"prop":"isOpen"}},[_c('v-select',{attrs:{"options":_vm.isOpenOps,"placeholder":"请选择"},model:{value:(_vm.form.isOpen),callback:function ($$v) {_vm.$set(_vm.form, "isOpen", $$v)},expression:"form.isOpen"}})],1),_c('el-form-item',{attrs:{"label":"是否隐藏","rules":[
          { required: true, message: '请选择菜单类型', trigger: 'blur' },
        ],"prop":"hidden"}},[_c('v-select',{attrs:{"options":_vm.isHideMenuOps,"placeholder":"请选择"},model:{value:(_vm.form.hidden),callback:function ($$v) {_vm.$set(_vm.form, "hidden", $$v)},expression:"form.hidden"}})],1),_c('el-form-item',{attrs:{"label":"跳转第三方地址","rules":[
          {
            required: false,
            message: '请输入跳转第三方地址',
            trigger: 'blur',
          },
        ],"prop":"url"}},[_c('v-input',{attrs:{"placeholder":"请输入跳转第三方地址"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})],1),_c('el-form-item',{attrs:{"label":"重定向地址","rules":[
          { required: false, message: '请输入重定向地址', trigger: 'blur' },
        ],"prop":"redirect"}},[_c('v-input',{attrs:{"placeholder":"请输入重定向地址"},model:{value:(_vm.form.redirect),callback:function ($$v) {_vm.$set(_vm.form, "redirect", $$v)},expression:"form.redirect"}})],1),_c('el-form-item',{attrs:{"label":"激活地址","rules":[
          { required: false, message: '请输入激活地址', trigger: 'blur' },
        ],"prop":"currentPath"}},[_c('v-input',{attrs:{"placeholder":"请输入激活地址"},model:{value:(_vm.form.currentPath),callback:function ($$v) {_vm.$set(_vm.form, "currentPath", $$v)},expression:"form.currentPath"}})],1),_c('el-form-item',{attrs:{"label":"备注地址","rules":[
          { required: false, message: '请输入备注地址', trigger: 'blur' },
        ],"prop":"remark"}},[_c('v-input',{attrs:{"placeholder":"请输入备注地址"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }